import React, { useState, useEffect, useRef } from "react";
import { io } from "socket.io-client";
import moment from "moment";
import { getChatsApi } from "../components/Api's/Api";
import { Spinner } from "react-bootstrap";
import { avatarImage } from "../GlobalCss/Objects";
import { s3BaseUrl } from "../components/Variable";
import emptyImg from "../../images/emptyImg.jpg";
import groupImage from "../../images/nursingLogo.svg";
import S3Image from "../components/S3Imge";

let socket = "";

const ChatScreen = (props) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const lastMessageRef = useRef();
  const [loader, setLoader] = useState(false);
  const token = localStorage.getItem("nursingToken");

  const conversationId = props?.location?.state?.conversationId?._id;
  const data = props?.location?.state?.data;

  async function getGroupsChat() {
    setLoader(true);
    try {
      const response = await getChatsApi(conversationId);
      console.log(response);
      const messagesData = response?.data?.data;
      setMessages(messagesData);
    } catch (error) {
      console.log(error.response);
    } finally {
      setLoader(false);
    }
  }
  //===================Import Icon function================================
  const findAvatarItem = (user) => {
    return avatarImage.find(
      (item) => user?.profile?.profileImage === item.name
    );
  };
  const onImageError = (e) => {
    e.target.src = emptyImg;
  };

  const setupSocket = () => {
    socket = io(
      `https://api.nspocketconnect.com?token=${token}&conversationId=${conversationId}`,
      {}
    );

    socket.on("connect", (data) => {
      console.log("🔥: A user connected successfully!");
    });

    socket.on("receiveMessage", (data) => {
      console.log("Received Message:", data);
      const newMessage = {
        type: data?.type,
        message: data?.message,
        role: data?.role,
        createdAt: new Date(data.createdAt),
        sender: data?.sender,
        name: data?.name,
      };

      setMessages((prevMessages) => [newMessage, ...prevMessages]);
    });
  };

  const handleSendMessage = () => {
    if (newMessage.trim() !== "") {
      const newMessageObj = {
        message: newMessage,
        type: "msg",
        role: "admin",
        conversationId: conversationId,
        chatType: "public",
        publicChat: true,
      };

      if (socket) {
        socket.emit("sendMessage", newMessageObj);
      }
      setNewMessage("");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  useEffect(() => {
    getGroupsChat();
  }, [conversationId]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      lastMessageRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }, 0);

    return () => clearTimeout(timeoutId);
  }, [messages]);

  useEffect(() => {
    setupSocket();
    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <div className="">
      <div className="d-flex group-details">
        <div>
          {/* <S3Image imageKey={data?.image} /> */}
          <img src={groupImage} height={60} width={60} />
        </div>
        <div className="d-flex flex-column">
          <h3 className="m-0">{data?.groupName}</h3>
          <p className="m-0">
            {data?.description.length > 50
              ? data?.description.slice(0, 40) + "..."
              : data?.description}
          </p>
        </div>
      </div>
      <div className="chat-container">
        <div className="chatContainer">
          {loader ? (
            <div className="d-flex justify-content-center align-items-center mt-5">
              <Spinner animation="border" />
            </div>
          ) : (
            <div className="messageList">
              {messages
                ?.map((message, index) => (
                  <>
                    {message.role === "admin" ? (
                      <div></div>
                    ) : (
                      <div>
                        {message?.sender?.profile?.profileType === "avtar" ? (
                          <img
                            height={30}
                            width={30}
                            className="user-chat-profile mt-1"
                            src={
                              (findAvatarItem(message?.sender) &&
                                findAvatarItem(message?.sender).src) ||
                              emptyImg
                            }
                            alt="user icon"
                            onError={onImageError}
                          />
                        ) : (
                          <img
                            height={30}
                            width={30}
                            className="user-chat-profile mt-1"
                            src={
                              message?.sender?.profile?.profileImage || emptyImg
                            }
                            alt="user icon"
                            onError={onImageError}
                          />
                        )}
                      </div>
                    )}
                    <div
                      className={`${
                        message.role === "admin"
                          ? "adminMessage"
                          : "userMessage"
                      }`}
                      key={index}
                    >
                      {message.role === "admin" ? (
                        <></>
                      ) : (
                        <span className="sender-name">
                          ~{message.sender?.userName}
                        </span>
                      )}
                      <div className="msg-list">
                        {message.type === "msg" && <p>{message.message}</p>}
                      </div>

                      <div className="msg-time">
                        <span>{moment(message?.createdAt).format("LT")}</span>
                      </div>
                    </div>
                  </>
                ))
                .reverse()}
              <div ref={lastMessageRef}></div>
            </div>
          )}
        </div>

        <div className="inputContainer">
          <textarea
            type="text"
            placeholder="Type your message..."
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            className="input"
            onKeyDown={handleKeyPress}
          />

          {newMessage.trim().length > 0 && (
            <button onClick={handleSendMessage} className="sendButton">
              Send
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatScreen;

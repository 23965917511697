import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import Button from "../Button";
import { uploadFile } from "react-s3";
import { Card } from "react-bootstrap";
import { addSubChildApi, config } from "../Api's/Api";
import AWS from "aws-sdk";

let link = "";

export default function AddSubChild({ showModal, onHide, list, id }) {
  const s3 = new AWS.S3();

  const [loader, setLoader] = useState(false);
  const [url, setUrl] = useState("");
  const [nameError, setNameError] = useState("");
  const [urlError, setUrlError] = useState("");
  const [urlName, setUrlName] = useState("");
  const [urlType, setUrlType] = useState("");
  const [selectImage, setSelectImage] = useState("");

  const handleUpload = async () => {
    if (!url) {
      console.error("No file selected");
      return;
    }
    setLoader(true);

    const params = {
      Bucket: process.env.REACT_APP_BUCKET_NAME,
      Key: url?.name,
      Body: url,
    };

    try {
      const data = await s3.upload(params).promise();
      setSelectImage(data);
    } catch (error) {
      console.error("Upload failed:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (urlType === "pdf url") {
      handleUpload();
      setUrl("");
    }
  }, [url]);

  async function handleFormSubmit(e) {
    e.preventDefault();

    if (/^\s+$/.test(urlName)) {
      setNameError("urlName cant be blank");
      return;
    }
    if (/^\s+$/.test(url)) {
      setUrlError("Url cant be blank");
      return;
    }

    setLoader(true);

    if (selectImage?.Location?.length > 0) {
      link = selectImage?.Location;
    } else {
      link = url;
    }

    try {
      const res = await addSubChildApi(link, urlType, urlName, id);
      if (res?.status === 200) {
        toast.success("Sub-Subtopic added successfully");
        list();
        onHide();
        setLoader(false);
      } else if (!res?.status === 200) {
        throw new Error("response.message");
      }
    } catch (error) {
      setLoader(false);
      toast.error(error?.response?.data?.message);
    }
  }

  return (
    <div>
      <Modal show={showModal} onHide={onHide} centered>
        <Card style={{ width: "100%" }}>
          <span className="close" onClick={onHide}>
            &times;
          </span>
          <form className="p-3 mt-2" onSubmit={handleFormSubmit}>
            <h3 className="text-center m-0 mb-3">Add SubChild</h3>

            <label className="text-black font-w500">SubTopic Type</label>
            <div className="form-field">
              <select
                style={{ border: "none", borderRadius: "30px" }}
                required="required"
                className="form-control"
                value={urlType}
                onChange={(e) => setUrlType(e.target.value)}
              >
                <option hidden>Select..</option>
                <option value="youtube url">Youtube url</option>
                <option value="pdf url">Pdf url</option>
              </select>
            </div>
            {urlType === "pdf url" ? (
              <>
                <label className="text-black">Select File</label>
                <div className="form-field">
                  <input
                    type="file"
                    accept=".pdf"
                    onChange={(e) => setUrl(e.target.files[0])}
                  />
                </div>
              </>
            ) : (
              <>
                <label className="text-black">Enter Url</label>
                <div className="form-field" style={{ color: "red" }}>
                  <input
                    type="url"
                    required
                    value={url}
                    autocomplete="off"
                    placeholder="url"
                    onChange={(e) => setUrl(e.target.value)}
                  />
                </div>
                <span className="text-danger ml-2">{urlError}</span>
              </>
            )}
            <label className="text-black">Url Name</label>
            <div class="form-field" style={{ color: "red" }}>
              <input
                type="text"
                autocomplete="off"
                required
                placeholder="Enter url Name"
                value={urlName}
                onChange={(e) => setUrlName(e.target.value)}
              />
            </div>
            <span className="text-danger ml-2 ">{nameError}</span>

            <div className="d-flex align-items-center justify-content-end">
              <Button loader={loader} />
            </div>
          </form>
        </Card>
      </Modal>
    </div>
  );
}

import React from "react";

export default function Filters({
  tabs,
  FilterOption,
  filterType,
  setFilterType,
  type,
  setSearch,
}) {
  return (
    <div>
      {tabs && (
        <div className="filter-tabs">
          {FilterOption.map((option, index) => (
            <p
              onClick={() => setFilterType(option.value)}
              className={`${
                option.value === filterType ? "active-tab" : "tab"
              }`}
            >
              {option.label}
            </p>
          ))}
        </div>
      )}

      {type === "search" && (
        <div class="form-group" style={{ width: "40%" }}>
          <input
            type="text"
            id="search-box"
            className="form-control"
            placeholder="Search"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      )}
    </div>
  );
}

import { useState } from "react";
import { Modal } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import Button from "../Button";
import { Card } from "react-bootstrap";
import { addUserApi } from "../Api's/Api";

export default function AddUserModal({ show, onHide, list }) {
  const [loader, setLoader] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  let errorsObj = { email: "", password: "", name: "" };
  const [errors, setErrors] = useState(errorsObj);

  async function handleFormSubmit(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (name.trim() === "") {
      errorObj.name = "name can't be blank";
      error = true;
    }
    if (!email.trim().includes("@")) {
      errorObj.email = "Invalid email";
      error = true;
    }
    if (password.trim().name < 3) {
      errorObj.password = "name must be at least 3 characters";
      error = true;
    }
    if (password.trim().length < 6) {
      errorObj.password = "password must be at least 6 characters";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }
    setLoader(true);
    try {
      const res = await addUserApi(name, email, password);
      if (res?.status === 200) {
        toast.success("User added successfully");
        list();
        onHide();
        setLoader(false);
      } else if (!res?.status === 200) {
        throw new Error("response.message");
      }
    } catch (error) {
      setLoader(false);
      toast.error(error?.response?.data?.message);
    }
  }

  return (
    <div>
      <Modal show={show} onHide={onHide} centered>
        <Card style={{ width: "100%" }}>
          <span className="close" onClick={onHide}>
            &times;
          </span>
          <form className="p-3 mt-2" onSubmit={handleFormSubmit}>
            <h3 className="text-center m-0 mb-3">Add User</h3>

            <label className="text-black"> Name</label>
            <div class="form-field" style={{ color: "red" }}>
              <input
                type="text"
                autocomplete="off"
                required
                placeholder="Enter user name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {errors.name && (
                <div className="text-danger fs-12">{errors.name}</div>
              )}
            </div>
            <label className="text-black">Email</label>
            <div class="form-field" style={{ color: "red" }}>
              <input
                type="email"
                autocomplete="off"
                required
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            {errors.email && (
              <div className="text-danger fs-12">{errors.email}</div>
            )}
            <label className="text-black">Password</label>
            <div class="form-field" style={{ color: "red" }}>
              <input
                type="text"
                autocomplete="off"
                required
                placeholder="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            {errors.password && (
              <div className="text-danger fs-12">{errors.password}</div>
            )}
            <div className="d-flex align-items-center justify-content-end">
              <Button loader={loader} />
            </div>
          </form>
        </Card>
      </Modal>
    </div>
  );
}

import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  loadingToggleAction,
  loginAction,
} from "../../store/actions/AuthActions";
import logo from "../../images/nursingLogo.svg";
import "./Login.css";

function Login(props) {
  const [email, setEmail] = useState("");
  let errorsObj = { email: "", password: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [loader, setLoader] = useState(false);
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();

  async function onLogin(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (!email.includes("@")) {
      errorObj.email = "Invalid email";
      error = true;
    } else if (password.length <= 4) {
      errorObj.password = "Wrong password";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }
    dispatch(loadingToggleAction(true));
    dispatch(loginAction(email, password, props.history ,setLoader));
  }

  return (
    <div className="login-bg">
      <div class="wrapper">
        <div class="logo">
          <img src={logo} alt="Logo" />
        </div>
        <div
          class="text-center mt-4 name"
          style={{
            color: "black",
            marginTop: "",
            padding: "1.5px",
            textAlign: "center",
          }}
        >
          LogIn
        </div>
        {props.errorMessage && (
          <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2">
            {props.errorMessage}
          </div>
        )}
        {props.successMessage && (
          <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
            {props.successMessage}
          </div>
        )}
        <form class="p-3 mt-3" onSubmit={onLogin}>
          <div
            class="form-field d-flex align-items-center"
            style={{ color: "red" }}
          >
            <span className="fa fa-user"></span>
            <input
              type="email"
              autocomplete="off"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          {errors.email && (
            <div className="text-danger fs-12">{errors.email}</div>
          )}
          <div class="form-field d-flex align-items-center">
            <span className="fa fa-key"></span>
            <input
              type="password"
              autocomplete="off"
              value={password}
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          {errors.password && (
            <div className="text-danger fs-12">{errors.password}</div>
          )}
          {/* <button type="submit" class="bTn mt-3">
            Login
          </button> */}
          <button className={`bTn mt-3 ${loader ? 'disabled' : ''}`} disabled={loader} type="submit" style={ 
        loader
            ? { pointerEvents: "none", opacity: 0.5 }
            : { pointerEvents: "auto" }
    }>
        {loader ? (<div className="d-flex align-items-center justify-content-center " style={{gap:"5px"}}>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Loading...
        </div>)
            : ('Login')}
    </button>
        </form>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};
export default connect(mapStateToProps)(Login);

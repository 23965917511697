import { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import Lottie from "lottie-react";
import animationData from "../../../../images/nursing.json";
import { variable, s3BaseUrl } from "../../Variable";
import emptyImg from "../../../../images/emptyImg.jpg";
import { Card } from "react-bootstrap";
import { avatarImage } from "../../../GlobalCss/Objects";

import { userApi, addNewMemberGroupApi } from "../../Api's/Api";

export default function AddMembers({ showModal, onHide, List }) {
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checkbox, setCheckbox] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [search, setSearch] = useState("");
  const [userList, setUsersList] = useState("");
  const onImageError = (e) => {
    e.target.src = emptyImg;
  };
  console.log(variable.id);

  async function handleGroupUserList() {
    setLoader(true);
    const res = await userApi(search, variable?.id);
    setUsersList(res);
    setLoader(false);
  }
  console.log(userList);
  console.log(checkbox);

  const findAvatarItem = (user) => {
    return avatarImage.find(
      (item) => user?.profile?.profileImage === item.name
    );
  };

  const handleSelectAllChange = () => {
    const usersNotJoined = userList?.data?.data?.upDatedAllUsers?.filter(
      (user) => !user.isGroupJoined
    );
    if (!usersNotJoined.length) {
      return;
    }
    setSelectAll(!selectAll);
    if (!selectAll) {
      const allUserIds = usersNotJoined.map((user) => user._id);
      setCheckbox(allUserIds);
    } else {
      setCheckbox([]);
    }
  };

  function joinCheckbox(id) {
    const updatedCheckedIds = checkbox.includes(id)
      ? checkbox.filter((checkedId) => checkedId !== id)
      : [...checkbox, id];

    setCheckbox(updatedCheckedIds);
  }

  async function onHandleJoinGroup() {
    setLoading(true);
    try {
      const res = await addNewMemberGroupApi(checkbox, variable?.id);
      if (res?.status === 200) {
        toast.success("Added successfully");
        List();
        onHide();
        setLoading(false);
      } else if (!res?.status === 200) {
        throw new Error("response.message");
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message);
    }
  }

  useEffect(() => {
    if (search.length > 0) {
      handleGroupUserList();
    } else if (search.length === 0) {
      handleGroupUserList();
    }
  }, [search]);

  useEffect(() => {
    handleGroupUserList();
  }, []);

  return (
    <div>
      <Modal show={showModal} onHide={onHide} centered className="group-modal">
        <Card>
          <span className="close" onClick={onHide}>
            &times;
          </span>

          <div
            className="hide-scrollbar"
            style={{
              overflowY: "auto",
              height: "66vh",
            }}
          >
            <div className="p-1">
              <input
                type="text"
                placeholder="Search..."
                className="p-2 search-member"
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <div
              className="d-flex align-items-center p-2 justify-content-start group-content"
              style={{ gap: "10px" }}
            >
              <label className="checkbox-container">
                <input
                  type="checkbox"
                  onChange={handleSelectAllChange}
                  checked={selectAll}
                />
                <span className="checkmark"></span>
              </label>
              <span className="group-name">Select All</span>
            </div>
            <div
              style={{
                height: "1px",
                backgroundColor: "gray",
                width: "100%",
                padding: "0",
                opacity: "0.2",
              }}
            ></div>
            {loader ? (
              <div
                className="d-flex  justify-content-center align-items-center"
                style={{ height: "50vh" }}
              >
                {/* <Lottie animationData={animationData} /> */}
                <Spinner animation="border" />
              </div>
            ) : (
              <>
                {userList?.data?.data?.upDatedAllUsers?.map((item) => (
                  <div
                    className="d-flex align-items-center p-2 justify-content-start group-content"
                    // style={{ gap: "10px" }}
                    style={{ opacity: item.isGroupJoined ? 0.4 : 1 }}
                  >
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        onChange={() => joinCheckbox(item._id)}
                        checked={
                          checkbox.includes(item._id) || item.isGroupJoined
                        }
                        disabled={item?.isGroupJoined}
                      />
                      <span className="checkmark"></span>
                    </label>
                    {item?.profile?.profileType === "avtar" ? (
                      <img
                        style={{ borderRadius: "20px", marginLeft: "10px" }}
                        height={35}
                        width={35}
                        src={
                          (findAvatarItem(item) && findAvatarItem(item).src) ||
                          emptyImg
                        }
                        onError={onImageError}
                        alt="user icon"
                      />
                    ) : (
                      <img
                        style={{ borderRadius: "20px", marginLeft: "10px" }}
                        height={35}
                        width={35}
                        src={item?.profile?.profileImage || emptyImg}
                        onError={onImageError}
                        alt="user icon"
                      />
                    )}
                    <span className="group-name ml-2">{item?.userName}</span>
                  </div>
                ))}
                {userList?.data?.data?.upDatedAllUsers?.length === 0 && (
                  <div
                    className="justify-content-center d-flex align-items-center "
                    style={{ height: "50vh" }}
                  >
                    Sorry, Data Not Found!
                  </div>
                )}
              </>
            )}
          </div>
          <div className="mt-3 d-flex justify-content-center">
            {checkbox.length > 0 && (
              //   <button
              //     className={`join-button ${loading ? "disabled" : ""}`}
              //     disabled={loading}
              //     type="submit"
              //     style={
              //       loading
              //         ? { pointerEvents: "none", opacity: 0.5 }
              //         : { pointerEvents: "auto" }
              //     }
              //     onClick={() => onHandleJoinGroup()}
              //   >
              //     {loading ? (
              //       <div
              //         className="d-flex align-items-center justify-content-center "
              //         style={{ gap: "5px" }}
              //       >
              //         <span
              //           className="spinner-border spinner-border-sm"
              //           role="status"
              //           aria-hidden="true"
              //         ></span>
              //         Loading...
              //       </div>
              //     ) : (
              //       "Join"
              //     )}
              //   </button>
              <button
                className={`join-button ${
                  loading ||
                  checkbox.some(
                    (id) =>
                      userList?.data?.data?.upDatedAllUsers.find(
                        (user) => user._id === id
                      )?.isGroupJoined
                  )
                    ? "disabled"
                    : ""
                }`}
                disabled={
                  loading ||
                  checkbox.some(
                    (id) =>
                      userList?.data?.data?.upDatedAllUsers.find(
                        (user) => user._id === id
                      )?.isGroupJoined
                  )
                }
                type="submit"
                style={
                  loading ||
                  checkbox.some(
                    (id) =>
                      userList?.data?.data?.upDatedAllUsers.find(
                        (user) => user._id === id
                      )?.isGroupJoined
                  )
                    ? { pointerEvents: "none", opacity: 0.5 }
                    : { pointerEvents: "auto" }
                }
                onClick={() => onHandleJoinGroup()}
              >
                {loading ? (
                  <div
                    className="d-flex align-items-center justify-content-center "
                    style={{ gap: "5px" }}
                  >
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Loading...
                  </div>
                ) : (
                  "Join"
                )}
              </button>
            )}
          </div>
        </Card>
      </Modal>
    </div>
  );
}

import React, { Component, useEffect, useState } from "react";
import moment from "moment";
import toast, { Toaster } from "react-hot-toast";
import { variable, s3BaseUrl } from "../components/Variable";
import { Dropdown, Card, Table, Badge, Col } from "react-bootstrap";
import UserListingShimmerLoader from "./SkeletonPages/UserListingShimmerLoader";
import { topicsListApi, deleteTopicApi } from "../components/Api's/Api";
import Pagination from "../components/Pagination";
import emptyImg from "../../images/emptyImg.jpg";
import AddTopics from "../components/Modal/AddTopics";
import S3Image from "../components/S3Imge";

export function Topics(props) {
  const [topicsList, setTopicsList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [addTopicModal, setAddTopicModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const itemsPerPage = 10;
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  let subjectId = props?.location?.state?.item;

  const onImageError = (e) => {
    e.target.src = emptyImg;
  };

  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );

  //=========page calculate=============
  const totalItems = topicsList?.data?.data?.count;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  //=============fetch user List==================
  async function handleTopicList() {
    setLoader(true);
    const res = await topicsListApi(
      props?.location?.state?.item,
      currentPage,
      itemsPerPage
    );
    setTopicsList(res);
    setLoader(false);
  }

  ///===========delete user======================
  async function handleDeleteTopic(id) {
    try {
      const res = await deleteTopicApi(id, props?.location?.state?.item);
      handleTopicList();
    } catch (error) {
      console.error("Something bad happened");
      console.error(error.response);
      throw new Error(error);
    }
  }

  function deletePromise(id) {
    toast.promise(handleDeleteTopic(id, props?.location?.state?.item), {
      loading: "Saving...",
      success: "Delete Successfully!",
      error: "try latter.",
    });
  }

  useEffect(() => {
    handleTopicList();
    scrollToTop();
  }, [currentPage]);

  useEffect(() => {
    handleTopicList();
  }, []);

  return (
    <div>
      <div className="page-header d-flex justify-content-between align-items-center mb-3">
        <h3 className="page-title"> Topics </h3>
        <nav aria-label="">
          <ol className="d-flex ">
            <li className="breadcrumb-item">
              <a href="/subjects">Library</a>
            </li>
            <li className="breadcrumb-item">
              <a href="!#">Topics List</a>
            </li>
          </ol>
        </nav>
      </div>
      <div className="d-flex justify-content-end mb-3">
        <button
          className="btn btn-secondary btn-sm"
          type="button"
          onClick={() => setAddTopicModal(true)}
        >
          Add Topics +
        </button>
      </div>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th> image </th>
                      <th> Subject name </th>
                      {/* <th> Sub Topics </th> */}
                      <th> Created </th>
                      <th> Action </th>
                    </tr>
                  </thead>
                  {loader ? (
                    <UserListingShimmerLoader count={10} />
                  ) : (
                    <tbody>
                      {topicsList?.data?.data?.subject?.map((item) => (
                        <tr key={item?._id}>
                          <td className="py-1">
                            <S3Image imageKey={item?.image} />
                          </td>
                          <td>{item?.name}</td>
                          <td>{moment(item?.createdAt).format("ll")}</td>
                          <td>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="warning light"
                                className="light sharp btn i-false"
                              >
                                {svg1}
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() => {
                                    props.history.push({
                                      pathname: "/sub-topics",
                                      state: {
                                        item: topicsList?.data?.data?.subject,
                                        id: item?._id,
                                        subjectId: subjectId,
                                      },
                                    });
                                  }}
                                >
                                  Sub Topics
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => deletePromise(item?._id)}
                                >
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
                {topicsList?.data?.data?.subject?.topics?.length === 0 && (
                  <div className="justify-content-center d-flex my-5 ">
                    Sorry, Data Not Found!
                  </div>
                )}
                {topicsList?.length !== 0 && (
                  <div className="card-footer clearfix">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Page {currentPage * itemsPerPage + 1} to{" "}
                        {totalItems > (currentPage + 1) * itemsPerPage
                          ? (currentPage + 1) * itemsPerPage
                          : totalItems}{" "}
                        of {totalItems} entries
                      </div>

                      <Pagination
                        pageCount={totalPages}
                        pageValue={currentPage}
                        setPage={setCurrentPage}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {addTopicModal && (
        <AddTopics
          showModal={addTopicModal}
          onHide={() => setAddTopicModal(false)}
          List={handleTopicList}
          subjectId={subjectId}
        />
      )}
    </div>
  );
}

export default Topics;

import React, { Component, useEffect, useState, lazy } from "react";
import moment from "moment";
import Pagination from "../components/Pagination";
import toast, { Toaster } from "react-hot-toast";
import { variable, s3BaseUrl } from "../components/Variable";
import { Dropdown, Card, Table, Badge, Col } from "react-bootstrap";
import UserListingShimmerLoader from "./SkeletonPages/UserListingShimmerLoader";
import { subjectListApi, deleteSubjectApi } from "../components/Api's/Api";
import emptyImg from "../../images/emptyImg.jpg";
const AddSubjects = lazy(() => import("../components/Modal/AddSubjects"));

export function Subjects(props) {
  const [subjectList, setSubjectList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const itemsPerPage = 10;
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const onImageError = (e) => {
    e.target.src = emptyImg;
  };

  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );

  //=========page calculate=============
  const totalItems = subjectList?.data?.data?.countSubjects;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  //=============fetch user List==================
  async function handleSubjectList() {
    setLoader(true);
    const res = await subjectListApi(currentPage, itemsPerPage);
    setSubjectList(res);
    setLoader(false);
  }

  ///===========delete user======================
  async function handleDelete(id) {
    try {
      const res = await deleteSubjectApi(id);
      handleSubjectList();
    } catch (error) {
      console.error("Something bad happened");
      console.error(error.response);
      throw new Error(error);
    }
  }

  function deletePromise(id) {
    toast.promise(handleDelete(id), {
      loading: "Saving...",
      success: "Delete Successfully!",
      error: "try latter.",
    });
  }

  useEffect(() => {
    handleSubjectList();
    scrollToTop();
  }, [currentPage]);

  return (
    <div>
      <div className="page-header d-flex justify-content-between align-items-end mb-3">
        <h3 className="page-title m-0"> Library List </h3>
        <div className="">
          <button
            className="btn btn-secondary btn-sm"
            type="button"
            onClick={() => setShowModal(true)}
          >
            Add Subject +
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      {/* <th> image </th> */}
                      <th> Subject name </th>
                      {/* <th> Topics </th> */}
                      <th> Creation Date </th>
                      <th> Action </th>
                    </tr>
                  </thead>
                  {loader ? (
                    <UserListingShimmerLoader count={10} />
                  ) : (
                    <tbody>
                      {subjectList?.data?.data?.subjects?.map((item) => (
                        <tr key={item?._id}>
                          {/* <td className="py-1">
                            <img
                              className="profile-img"
                              src={`${s3BaseUrl}${item?.image}` || emptyImg}
                              onError={onImageError}
                              alt="user icon"
                            />
                          </td> */}
                          <td>{item?.name}</td>
                          {/* <td>{"Topic"}</td>. */}
                          <td>{moment(item?.createdAt).format("ll")}</td>
                          <td>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="warning light"
                                className="light sharp btn i-false"
                              >
                                {svg1}
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() => {
                                    variable.id = item?._id;
                                    props.history.push({
                                      pathname: "/topics",
                                      state: { item: item?._id },
                                    });
                                  }}
                                >
                                  Topics
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => deletePromise(item?._id)}
                                >
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
                {subjectList?.data?.data?.subjects?.length === 0 && (
                  <div className="justify-content-center d-flex my-5 ">
                    Sorry, Data Not Found!
                  </div>
                )}
                {subjectList?.length !== 0 && (
                  <div className="card-footer clearfix">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Page {currentPage * itemsPerPage + 1} to{" "}
                        {totalItems > (currentPage + 1) * itemsPerPage
                          ? (currentPage + 1) * itemsPerPage
                          : totalItems}{" "}
                        of {totalItems} entries
                      </div>

                      <Pagination
                        pageCount={totalPages}
                        pageValue={currentPage}
                        setPage={setCurrentPage}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <AddSubjects
          showModal={showModal}
          onHide={() => setShowModal(false)}
          List={handleSubjectList}
        />
      )}
    </div>
  );
}

export default Subjects;

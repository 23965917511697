import React, { Component, useEffect, useState } from "react";
import moment from "moment";
import toast, { Toaster } from "react-hot-toast";
import { variable, s3BaseUrl } from "../../components/Variable";
import { Dropdown, Card, Table, Badge, Col } from "react-bootstrap";
import UserListingShimmerLoader from "../SkeletonPages/UserListingShimmerLoader";
import { groupListApi, deleteGroupApi } from "../../components/Api's/Api";
import Pagination from "../../components/Pagination";
import AddMembers from "../../components/Modal/GroupsManagementModal/AddMembers";
import AddGroup from "../../components/Modal/GroupsManagementModal/AddGroup";
import groupImage from "../../../images/nursingLogo.svg";
import AddBlockingWords from "../../components/Modal/AddBlockingWords";

export function GroupsManagement(props) {
  const [groupList, setGroupList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [addGroupModal, setAddGroupModal] = useState(false);
  const [addNewMember, setAddNewMember] = useState(false);
  const [addWordsModal, setAddWordsModal] = useState(false);

  const itemsPerPage = 10;
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );

  //=========page calculate=============
  const totalItems = groupList?.data?.data?.countGroups;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  //=============fetch user List==================
  async function handleGroupList() {
    setLoader(true);
    const res = await groupListApi(currentPage, itemsPerPage);
    setGroupList(res);
    setLoader(false);
  }
  ///===========delete user======================
  async function handleDeleteGroup(id) {
    try {
      const res = await deleteGroupApi(id);
      handleGroupList();
    } catch (error) {
      console.error("Something bad happened");
      console.error(error.response);
      throw new Error(error);
    }
  }

  function deleteUserPromise(id) {
    toast.promise(handleDeleteGroup(id), {
      loading: "Saving...",
      success: "Delete Successfully!",
      error: "try latter.",
    });
  }

  useEffect(() => {
    handleGroupList();
    scrollToTop();
  }, [currentPage]);

  return (
    <div>
      <div className="page-header d-flex justify-content-between align-items-center mb-3">
        <h3 className="page-title m-0"> Group Management </h3>
        <div className="d-flex justify-content-end">
          <button
            className="btn btn-secondary btn-sm mr-2"
            type="button"
            onClick={() => setAddWordsModal(true)}
          >
            Block Words
          </button>
          <button
            className="btn btn-secondary btn-sm"
            type="button"
            onClick={() => setAddGroupModal(true)}
          >
            Add Group +
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead style={{ textAlign: "center" }}>
                    <tr>
                      <th> image </th>
                      <th> Group name </th>
                      <th> Description </th>
                      <th> Created </th>
                      <th> Action </th>
                    </tr>
                  </thead>
                  {loader ? (
                    <UserListingShimmerLoader count={10} />
                  ) : (
                    <tbody style={{ textAlign: "center" }}>
                      {groupList?.data?.data?.groups?.map((item) => (
                        <tr key={item?._id} style={{ cursor: "pointer" }}>
                          <td className="py-1 ">
                            <img src={groupImage} height={50} width={50} />
                          </td>
                          <td>
                            {item?.firstName} <span>{item?.groupName}</span>
                          </td>
                          <td>
                            {item?.description.length > 50
                              ? item?.description.slice(0, 30) + "..."
                              : item?.description}
                          </td>
                          <td>{moment(item?.createdAt).format("ll")}</td>
                          <td>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="warning light"
                                className="light sharp btn i-false"
                              >
                                {svg1}
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() => {
                                    props.history.push({
                                      pathname: "/chat-screen",
                                      state: {
                                        item: item?._id,
                                        conversationId: item?.conversationId,
                                        data: item,
                                      },
                                    });
                                  }}
                                >
                                  Go to Chat
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => {
                                    props.history.push({
                                      pathname: "/group-members",
                                      state: { item: item?._id },
                                    });
                                  }}
                                >
                                  Members
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => {
                                    setAddNewMember(true);
                                    variable.id = item._id;
                                  }}
                                >
                                  Add Members
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => deleteUserPromise(item?._id)}
                                >
                                  Delete
                                </Dropdown.Item>
                                {/* <Dropdown.Item>Block</Dropdown.Item> */}
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
                {groupList?.data?.data?.groups?.length === 0 && (
                  <div className="justify-content-center d-flex my-5 ">
                    Sorry, Data Not Found!
                  </div>
                )}
                {groupList?.length !== 0 && (
                  <div className="card-footer clearfix">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Showing {currentPage * itemsPerPage + 1} to{" "}
                        {totalItems > (currentPage + 1) * itemsPerPage
                          ? (currentPage + 1) * itemsPerPage
                          : totalItems}{" "}
                        of {totalItems} entries
                      </div>

                      <Pagination
                        pageCount={totalPages}
                        pageValue={currentPage}
                        setPage={setCurrentPage}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {addGroupModal && (
        <AddGroup
          showModal={addGroupModal}
          onHide={() => setAddGroupModal(false)}
          List={handleGroupList}
        />
      )}
      {addNewMember && (
        <AddMembers
          showModal={addNewMember}
          onHide={() => setAddNewMember(false)}
          List={handleGroupList}
        />
      )}
      {addWordsModal && (
        <AddBlockingWords
          showModal={addWordsModal}
          onHide={() => setAddWordsModal(false)}
        />
      )}
    </div>
  );
}

export default GroupsManagement;

import { useState } from "react";
import { Modal } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { Card } from "react-bootstrap";
import Button from "../../Button";
import { addGroupApi } from "../../Api's/Api";

export default function AddGroup({ showModal, onHide, List }) {
  const [loader, setLoader] = useState(false);
  const [nameError, setNameError] = useState("");

  const [formData, setFormData] = useState({
    name: "",
    description: "",
  });
  async function handleFormSubmit(e) {
    e.preventDefault();
    if (formData?.name?.trim() === "") {
      setNameError("Name cant be blank");
      return;
    }
    setLoader(true);
    try {
      const res = await addGroupApi(formData);
      if (res?.status === 200) {
        toast.success("Added successful!");
        List();
        onHide();
        setLoader(false);
      } else if (!res?.status === 200) {
        throw new Error("response.message");
      }
    } catch (error) {
      setLoader(false);
      toast.error(error?.response?.data?.message);
    }
  }

  return (
    <div>
      <Modal show={showModal} onHide={onHide} centered>
        <Card style={{ width: "100%" }}>
          <span className="close" onClick={onHide}>
            &times;
          </span>
          <form className="p-3 mt-2" onSubmit={handleFormSubmit}>
            <h3 className="text-center m-0 mb-3">Add Group</h3>
            {/* <label className="text-black">Image</label>
            <div class="form-field" style={{ color: "red" }}>
              <input
                type="file"
                // required
                autocomplete="off"
                accept=".png, .jpg, .jpeg"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    image: e.target.files[0],
                  })
                }
              />
            </div> */}
            <label className="text-black">Group Name</label>
            <div class="form-field " style={{ color: "red" }}>
              <input
                type="text"
                autocomplete="off"
                required
                placeholder="Enter name"
                value={formData?.name}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    name: e.target.value,
                  });
                  setNameError("");
                }}
              />
              <span className="text-danger">{nameError}</span>
            </div>

            <label className="text-black">Description</label>
            <div class="form-field text-area-div" style={{ color: "red" }}>
              <textarea
                className="text-area"
                type="text"
                autocomplete="off"
                required
                placeholder="Type here..."
                value={formData?.description}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    description: e.target.value,
                  })
                }
              />
            </div>

            <div className="d-flex align-items-center justify-content-end">
              <Button loader={loader} />
            </div>
          </form>
        </Card>
      </Modal>
    </div>
  );
}

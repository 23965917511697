import React, { useState, useEffect } from "react";
import { Card, Spinner } from "react-bootstrap";
import moment from "moment";
import { Link } from "react-router-dom";
import emptyImg from "../../images/emptyImg.jpg";
import ProfileSkeleton from "../pages/SkeletonPages/ProfileSkeleton";
import {
  userMessageHistoryApi,
  viewUserDetails,
} from "../components/Api's/Api";
import { avatarImage } from "../GlobalCss/Objects";
import Pagination from "../components/Pagination";
import toast from "react-hot-toast";
// import Pagination from "../../components/Pagination";

export default function UserDetail(props) {
  const [UserDetails, setUserDetails] = useState([]);
  const [loader, setLoader] = useState(true);
  const [isLoader, setIsLoader] = useState(true);

  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState("");
  const [messageListHistory, setMessageListHistory] = useState([]);

  const itemsPerPage = 10;

  const id = props?.location?.state.item;

  const onImageError = (e) => {
    e.target.src = emptyImg;
  };

  const findAvatarItem = (user) => {
    return avatarImage.find(
      (item) => user?.profile?.profileImage === item.name
    );
  };

  //============userDetails================================
  async function viewUserDetailsApi() {
    const res = await viewUserDetails(id);
    console.log(res);
    setUserDetails(res);
    setLoader(false);
  }

  //=========page calculate=============
  const totalItems = messageListHistory?.data?.data?.countChats;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  //=============fetch user List==================
  async function handleUserHistoryList() {
    setIsLoader(true);
    try {
      const res = await userMessageHistoryApi(
        id,
        currentPage,
        itemsPerPage,
        search
      );

      setMessageListHistory(res);
    } catch (e) {
      toast.error(
        e?.response?.data?.message
          ? e.response.data.message
          : e?.response?.data?.data
      );
    } finally {
      setIsLoader(false);
    }
  }

  useEffect(() => {
    viewUserDetailsApi();
  }, [id]);
  useEffect(() => {
    handleUserHistoryList();
  }, [id, currentPage]);

  let item = UserDetails?.data?.data;

  return (
    <>
      <div>
        <div className="page-titles">
          <h4>Users Details</h4>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="user-view">Users</Link>
            </li>
            <li className="breadcrumb-item active d-flex align-items-center">
              <Link>Users Details</Link>
            </li>
          </ol>
        </div>
        {loader ? (
          <ProfileSkeleton />
        ) : (
          <Card>
            <Card.Header>
              <div
                className="d-flex bio-user p-3"
                style={{ gap: "1rem", flexGrow: "1" }}
              >
                <div>
                  {item?.profile?.profileType === "avtar" ? (
                    <img
                      className="img-profile"
                      width={110}
                      height={110}
                      src={
                        (findAvatarItem(item) && findAvatarItem(item).src) ||
                        emptyImg
                      }
                      onError={onImageError}
                      alt="user icon"
                    />
                  ) : (
                    <img
                      className="img-profile"
                      width={110}
                      height={110}
                      src={item?.profile?.profileImage || emptyImg}
                      onError={onImageError}
                      alt="user icon"
                    />
                  )}
                </div>
                <div className="text-left" style={{ flex: "1" }}>
                  <h3 className="text-color ">
                    {item?.firstName} <span>{item?.lastName}</span>
                  </h3>
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="m-0">Email</h5>
                    <p className="m-0">{item?.email}</p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="m-0">Created At</h5>
                    <p className="m-0">
                      {moment(item?.createdAt).format("ll")}
                    </p>
                  </div>
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              <div className="bio-user">
                <h5 style={{ margin: "0", padding: "15px 10px 0px 10px " }}>
                  Bio:
                </h5>
                <p style={{ marginLeft: "10px", textAlign: "start" }}>
                  {" "}
                  {item?.bio}
                </p>
              </div>
              <div
                style={{
                  height: "1px",
                  marginTop: "px",
                  Width: "100%",
                  opacity: ".5",
                  backgroundColor: "#c6c9c9ee",
                }}
              ></div>
              <div className="text-black pt-3">
                <h4 className="mb-3">History</h4>
                <div className=" mt-0 bio-user ">
                  {isLoader ? (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ height: "200px" }}
                    >
                      <Spinner animation="border" />
                    </div>
                  ) : (
                    <div className="card-body p-1">
                      <div className="table-responsive">
                        <table className="table table-striped">
                          <thead style={{ textAlign: "start" }}>
                            <tr>
                              <th> group name </th>
                              <th> message </th>
                              <th> date & time </th>
                            </tr>
                          </thead>

                          <tbody style={{ textAlign: "start" }}>
                            {messageListHistory?.data?.data?.chats?.map(
                              (item) => (
                                <tr key={item?._id}>
                                  <td>
                                    {item?.conversationId?.groupId?.groupName}
                                  </td>
                                  <td style={{ maxWidth: "300px" }}>
                                    {item?.message}
                                  </td>
                                  <td>
                                    {moment(item?.createdAt).format("lll")}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>

                        {messageListHistory?.data?.data?.countChats === 0 && (
                          <div className="justify-content-center d-flex my-5 ">
                            Sorry, Data Not Found!
                          </div>
                        )}
                        {messageListHistory?.length !== 0 && (
                          <div className="card-footer clearfix">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="dataTables_info">
                                Showing {currentPage * itemsPerPage + 1} to{" "}
                                {totalItems > (currentPage + 1) * itemsPerPage
                                  ? (currentPage + 1) * itemsPerPage
                                  : totalItems}{" "}
                                of {totalItems} entries
                              </div>

                              <Pagination
                                pageCount={totalPages}
                                pageValue={currentPage}
                                setPage={setCurrentPage}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Card.Body>
          </Card>
        )}
      </div>
    </>
  );
}

import { useState } from "react";
import { Modal } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import Button from "../Button";
import { Card } from "react-bootstrap";
import { addSubTopicApi } from "../Api's/Api";

export default function AddSubTopic({
  subjectId,
  showModal,
  onHide,
  list,
  topicId,
}) {
  const [loader, setLoader] = useState(false);
  const [nameError, setNameError] = useState("");
  const [name, setName] = useState("");

  async function handleFormSubmit(e) {
    e.preventDefault();

    if (/^\s+$/.test(name)) {
      setNameError("Name cant be blank");
      return;
    }
    setLoader(true);
    try {
      const res = await addSubTopicApi(name, topicId, subjectId);
      if (res?.status === 200) {
        toast.success("Subtopic added successfully");
        list();
        onHide();
        setLoader(false);
      } else if (!res?.status === 200) {
        throw new Error("response.message");
      }
    } catch (error) {
      setLoader(false);
      toast.error(error?.response?.data?.message);
    }
  }

  return (
    <div>
      <Modal show={showModal} onHide={onHide} centered>
        <Card style={{ width: "100%" }}>
          <span className="close" onClick={onHide}>
            &times;
          </span>
          <form className="p-3 mt-2" onSubmit={handleFormSubmit}>
            <h3 className="text-center m-0 mb-3">Add Sub Topic</h3>

            <label className="text-black">SubTopic Name</label>
            <div class="form-field" style={{ color: "red" }}>
              <input
                type="text"
                autocomplete="off"
                required
                placeholder="Enter sub Topic"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <span className="text-danger ml-2">{nameError}</span>

            <div className="d-flex align-items-center justify-content-end">
              <Button loader={loader} />
            </div>
          </form>
        </Card>
      </Modal>
    </div>
  );
}

import React, { Component, useEffect, useState } from "react";
import moment from "moment";
import toast, { Toaster } from "react-hot-toast";
import { s3BaseUrl } from "../components/Variable";
import { Dropdown, Card, Table, Badge, Col } from "react-bootstrap";
import Pagination from "../components/Pagination";
import UserListingShimmerLoader from "./SkeletonPages/UserListingShimmerLoader";
import { subTopicsListApi, deleteSubTopicApi } from "../components/Api's/Api";
import AddSubTopic from "../components/Modal/AddSubTopic";

export function SubTopics(props) {
  const [subTopicsList, setTopicsList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [addSubTopicModal, setAddSubTopicModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const itemsPerPage = 10;
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  let subjectId = props?.location?.state?.subjectId;
  let topicId = props?.location?.state?.id;

  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );

  //=========page calculate=============
  const totalItems = subTopicsList?.data?.data?.countSubTopics;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  //=============fetch user List==================
  async function handleSubTopicList() {
    setLoader(true);
    const res = await subTopicsListApi(
      subjectId,
      topicId,
      currentPage,
      itemsPerPage
    );
    setTopicsList(res);
    setLoader(false);
  }

  ///===========delete user======================
  async function handleDeleteSubTopic(id) {
    try {
      const res = await deleteSubTopicApi(id, subjectId, topicId);
      handleSubTopicList();
    } catch (error) {
      console.error("Something bad happened");
      console.error(error.response);
      throw new Error(error);
    }
  }

  function deleteSubTopicPromise(id) {
    toast.promise(handleDeleteSubTopic(id), {
      loading: "Saving...",
      success: "Delete Successfully!",
      error: "try latter.",
    });
  }

  useEffect(() => {
    handleSubTopicList();
    scrollToTop();
  }, [currentPage]);

  useEffect(() => {
    handleSubTopicList();
  }, []);

  return (
    <div>
      <div className="page-header d-flex justify-content-between align-items-center mb-3">
        <h3 className="page-title"> Sub Topics </h3>
        <nav aria-label="">
          <ol className="d-flex ">
            <li className="breadcrumb-item">
              <a href="/subjects">Library</a>
            </li>
            <li className="breadcrumb-item">
              <a href="/topics">Topics</a>
            </li>
          </ol>
        </nav>
      </div>
      <div className="d-flex justify-content-end mb-3">
        <button
          className="btn btn-secondary btn-sm"
          type="button"
          onClick={() => setAddSubTopicModal(true)}
        >
          Add SubTopic +
        </button>
      </div>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      {/* <th> image </th> */}
                      <th> SubTopic name </th>
                      {/* <th> Sub Child </th> */}
                      <th> Created </th>
                      <th> Action </th>
                    </tr>
                  </thead>
                  {loader ? (
                    <UserListingShimmerLoader count={10} />
                  ) : (
                    <tbody>
                      {subTopicsList?.data?.data?.subTopics?.map((item) => (
                        <tr key={item?._id}>
                          {/* <td className="py-1">
                            <img
                              src={`${s3BaseUrl}${item?.image}` || emptyImg}
                              height={40}
                              width={40}
                              onError={onImageError}
                              alt="user icon"
                            />
                          </td> */}
                          <td>{item?.name}</td>
                          {/* <td>{"jnfjkg"}</td> */}
                          <td>{moment(item?.createdAt).format("ll")}</td>
                          <td>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="warning light"
                                className="light sharp btn i-false"
                              >
                                {svg1}
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() => {
                                    props.history.push({
                                      pathname: "/sub-child",
                                      state: { item: item?._id },
                                    });
                                  }}
                                >
                                  Sub-subTopic
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() =>
                                    deleteSubTopicPromise(item?._id)
                                  }
                                >
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
                {subTopicsList?.data?.data?.subTopics?.length === 0 && (
                  <div className="justify-content-center d-flex my-5 ">
                    Sorry, Data Not Found!
                  </div>
                )}
                {subTopicsList?.length !== 0 && (
                  <div className="card-footer clearfix">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Page {currentPage * itemsPerPage + 1} to{" "}
                        {totalItems > (currentPage + 1) * itemsPerPage
                          ? (currentPage + 1) * itemsPerPage
                          : totalItems}{" "}
                        of {totalItems} entries
                      </div>

                      <Pagination
                        pageCount={totalPages}
                        pageValue={currentPage}
                        setPage={setCurrentPage}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {addSubTopicModal && (
        <AddSubTopic
          subjectId={subjectId}
          showModal={addSubTopicModal}
          onHide={() => setAddSubTopicModal(false)}
          list={handleSubTopicList}
          topicId={topicId}
        />
      )}
    </div>
  );
}

export default SubTopics;

import React, { Component, useEffect, useState } from "react";
import moment from "moment";
import toast, { Toaster } from "react-hot-toast";
import { variable, s3BaseUrl } from "../components/Variable";
import { Dropdown, Card, Table, Badge, Col } from "react-bootstrap";
import UserListingShimmerLoader from "./SkeletonPages/UserListingShimmerLoader";
import { subChildListApi, deleteSubChildApi } from "../components/Api's/Api";
import Pagination from "../components/Pagination";
import EditSubChild from "../components/Modal/EditSubChild";
import AddSubChild from "../components/Modal/AddSubChild";

export function SubChild(props) {
  const [subChildList, setSubChildList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [addSubChildModal, setAddSubChildModal] = useState(false);
  const [editSubChildModal, setEditSubChildModal] = useState(false);
  const [editData, setEditData] = useState();
  const [currentPage, setCurrentPage] = useState(0);

  const itemsPerPage = 10;
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  let subTopicId = props?.location?.state?.item;

  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );

  //=========page calculate=============
  const totalItems = subChildList?.data?.data?.countChildSubTopic;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  //=============fetch user List==================
  async function handleSubChildList() {
    setLoader(true);
    const res = await subChildListApi(subTopicId);
    setSubChildList(res);
    setLoader(false);
  }

  //===========delete user======================
  async function handleDeleteChild(id) {
    try {
      const res = await deleteSubChildApi(id, subTopicId);
      handleSubChildList();
    } catch (error) {
      console.error("Something bad happened");
      console.error(error.response);
      throw new Error(error);
    }
  }

  function deleteSubChildPromise(id) {
    toast.promise(handleDeleteChild(id), {
      loading: "Saving...",
      success: "Delete Successfully!",
      error: "try latter.",
    });
  }

  useEffect(() => {
    handleSubChildList();
    scrollToTop();
  }, [currentPage]);

  useEffect(() => {
    handleSubChildList();
  }, []);

  return (
    <div>
      <div className="page-header d-flex justify-content-between align-items-center mb-3">
        <h3 className="page-title"> Sub Subtopic </h3>
        <nav aria-label="">
          <ol className="d-flex ">
            <li className="breadcrumb-item">
              <a href="/subjects">Library</a>
            </li>
            <li className="breadcrumb-item">
              <a href="/topics">Topics</a>
            </li>
            <li className="breadcrumb-item">
              <a href="sub-topics">Sub Topics List</a>
            </li>
          </ol>
        </nav>
      </div>
      <div className="d-flex justify-content-end mb-3">
        <button
          className="btn btn-secondary btn-sm"
          type="button"
          onClick={() => setAddSubChildModal(true)}
        >
          Add file +
        </button>
      </div>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th> File </th>
                      <th> File Type </th>
                      <th> Created </th>
                      <th> Action </th>
                    </tr>
                  </thead>
                  {loader ? (
                    <UserListingShimmerLoader count={10} />
                  ) : (
                    <tbody>
                      {subChildList?.data?.data?.subTopic?.childSubTopics?.map(
                        (item) => (
                          <tr key={item?._id}>
                            <td>
                              {item?.urlName}{" "}
                              <a href={item?.url}>
                                <i class="fa fa-external-link"></i>{" "}
                              </a>
                            </td>
                            <td>{item?.urlType}</td>
                            <td>{moment(item?.createdAt).format("ll")}</td>
                            <td>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="warning light"
                                  className="light sharp btn i-false"
                                >
                                  {svg1}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  {/* <Dropdown.Item
                                    onClick={() => {
                                      setEditData(item);
                                      setEditSubChildModal(true);
                                    }}
                                  >
                                    Edit
                                  </Dropdown.Item> */}
                                  <Dropdown.Item
                                    onClick={() =>
                                      deleteSubChildPromise(item?._id)
                                    }
                                  >
                                    Delete
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  )}
                </table>
                {subChildList?.data?.data?.subTopic?.childSubTopics?.length ===
                  0 && (
                  <div className="justify-content-center d-flex my-5 ">
                    Sorry, Data Not Found!
                  </div>
                )}
                {subChildList?.length !== 0 && (
                  <div className="card-footer clearfix">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Page {currentPage * itemsPerPage + 1} to{" "}
                        {totalItems > (currentPage + 1) * itemsPerPage
                          ? (currentPage + 1) * itemsPerPage
                          : totalItems}{" "}
                        of {totalItems} entries
                      </div>

                      <Pagination
                        pageCount={totalPages}
                        pageValue={currentPage}
                        setPage={setCurrentPage}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {addSubChildModal && (
        <AddSubChild
          list={handleSubChildList}
          showModal={addSubChildModal}
          id={subTopicId}
          onHide={() => setAddSubChildModal(false)}
        />
      )}
      {editSubChildModal && (
        <EditSubChild
          list={handleSubChildList}
          showModal={editSubChildModal}
          id={subTopicId}
          onHide={() => setEditSubChildModal(false)}
          data={editData}
        />
      )}
    </div>
  );
}

export default SubChild;

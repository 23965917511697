import React, { Component, useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { variable } from "../components/Variable";
import { Dropdown, Card, Table, Badge, Col } from "react-bootstrap";
import UserListingShimmerLoader from "./SkeletonPages/UserListingShimmerLoader";
import {
  userListApi,
  deleteUserApi,
  blockUserApi,
} from "../components/Api's/Api";
import { avatarImage } from "../GlobalCss/Objects";
import Pagination from "../components/Pagination";
import JoinGroupList from "../components/Modal/GroupsManagementModal/JoinGroupList";
import emptyImg from "../../images/emptyImg.jpg";
import AddUserModal from "../components/Modal/AddUserModal";
import Filters from "../components/Filters";

export function UserView(props) {
  const [usersList, setUsersList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [groupModal, setGroupModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState("");
  const [addUserModal, setAddUserModal] = useState(false);

  const itemsPerPage = 10;
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const onImageError = (e) => {
    e.target.src = emptyImg;
  };

  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );

  const findAvatarItem = (user) => {
    return avatarImage.find(
      (item) => user?.profile?.profileImage === item.name
    );
  };

  //=========page calculate=============
  const totalItems = usersList?.data?.data?.countUser;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  //=============fetch user List==================
  async function handleUserList() {
    setLoader(true);
    const res = await userListApi(currentPage, itemsPerPage, search);

    setUsersList(res);
    setLoader(false);
  }

  ///===========delete user======================
  async function handleDeleteUser(id) {
    try {
      const res = await deleteUserApi(id);
      handleUserList();
    } catch (error) {
      console.error("Something bad happened");
      console.error(error.response);
      throw new Error(error);
    }
  }

  function deleteUserPromise(id) {
    toast.promise(handleDeleteUser(id), {
      loading: "Saving...",
      success: "Delete Successfully!",
      error: "try letter.",
    });
  }
  async function blockUser(id) {
    const res = await blockUserApi(id);

    if (res?.data?.data?.updateUser === "user Unblock successfully") {
      toast.success("User Unblock Successfully !");
      setLoader(false);
      handleUserList();
    } else {
      toast.error("User Blocked successful!");
      setLoader(false);
      handleUserList();
    }
  }

  useEffect(() => {
    handleUserList();
    scrollToTop();
  }, [currentPage, search]);

  return (
    <div>
      <div className="page-header d-flex justify-content-between align-items-baseline mb-3">
        <h3 className="page-title m-0"> User Management </h3>

        <div className="">
          <button
            className="btn btn-secondary btn-sm"
            type="button"
            onClick={() => setAddUserModal(true)}
          >
            Add User +
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <Filters type={"search"} setSearch={setSearch} />
          <div className="card">
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead style={{ textAlign: "center" }}>
                    <tr>
                      <th> image </th>
                      <th> User name </th>
                      <th> Email </th>
                      <th> status</th>
                      <th> Action </th>
                    </tr>
                  </thead>
                  {loader ? (
                    <UserListingShimmerLoader count={10} />
                  ) : (
                    <tbody style={{ textAlign: "center" }}>
                      {usersList?.data?.data?.Users?.map((item) => (
                        <tr key={item?._id}>
                          <td className="py-1">
                            {item?.profile?.profileType === "avtar" ? (
                              <img
                                className="profile-img"
                                style={{ borderRadius: "30px" }}
                                src={
                                  (findAvatarItem(item) &&
                                    findAvatarItem(item).src) ||
                                  emptyImg
                                }
                                onError={onImageError}
                                alt="user icon"
                              />
                            ) : (
                              <img
                                className="profile-img"
                                style={{ borderRadius: "30px" }}
                                src={item?.profile?.profileImage || emptyImg}
                                onError={onImageError}
                                alt="user icon"
                              />
                            )}
                          </td>
                          <td>{item?.userName}</td>
                          <td>{item?.email}</td>
                          <td>
                            {item.isBlocked === false ? (
                              <Badge variant="success light">Active</Badge>
                            ) : (
                              <Badge variant="danger light">Blocked</Badge>
                            )}
                          </td>
                          <td>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="warning light"
                                className="light sharp btn i-false"
                              >
                                {svg1}
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() => {
                                    variable.id = item._id;
                                    props.history.push({
                                      pathname: "/user-details",
                                      state: { item: item?._id },
                                    });
                                  }}
                                >
                                  View Details
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => {
                                    setGroupModal(true);
                                    variable.id = item._id;
                                  }}
                                >
                                  Join Group
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => deleteUserPromise(item?._id)}
                                >
                                  Delete
                                </Dropdown.Item>
                                {item.isBlocked === false ? (
                                  <Dropdown.Item
                                    onClick={() => blockUser(item._id)}
                                  >
                                    Block
                                  </Dropdown.Item>
                                ) : (
                                  <Dropdown.Item
                                    onClick={() => blockUser(item._id)}
                                  >
                                    Unblock
                                  </Dropdown.Item>
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
                {usersList?.data?.data?.Users?.length === 0 && (
                  <div className="justify-content-center d-flex my-5 ">
                    Sorry, Data Not Found!
                  </div>
                )}
                {usersList?.length !== 0 && (
                  <div className="card-footer clearfix">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Showing {currentPage * itemsPerPage + 1} to{" "}
                        {totalItems > (currentPage + 1) * itemsPerPage
                          ? (currentPage + 1) * itemsPerPage
                          : totalItems}{" "}
                        of {totalItems} entries
                      </div>

                      <Pagination
                        pageCount={totalPages}
                        pageValue={currentPage}
                        setPage={setCurrentPage}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {groupModal && (
        <JoinGroupList
          showModal={groupModal}
          onHide={() => setGroupModal(false)}
          list={handleUserList}
        />
      )}
      {addUserModal && (
        <AddUserModal
          show={addUserModal}
          onHide={() => setAddUserModal(false)}
          list={handleUserList}
        />
      )}
    </div>
  );
}

export default UserView;

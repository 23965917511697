import { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { Card } from "react-bootstrap";
import Button from "../Button";
import {
  addBlockWordApi,
  getBlockWordApi,
  deleteBlockingWordsApi,
} from "../Api's/Api";

export default function AddBlockingWords({ showModal, onHide }) {
  const [loader, setLoader] = useState(false);
  const [nameError, setNameError] = useState("");
  const [name, setName] = useState("");
  const [wordList, setWordList] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  //==================Get Block Word API =================
  async function getBlockWords() {
    setLoading(true);
    try {
      const responses = await getBlockWordApi();
      setData(responses);
    } catch (e) {
      toast.error(e?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  //=====================Add BlockingWords In Array =================
  function handleAddWords() {
    setWordList((prevWordList) => [...prevWordList, name.trim()]);
    setName("");
  }

  //=======================Remove Local BlockingWords=====================
  function handleRemoveWord(indexToRemove) {
    setWordList((prevWordList) =>
      prevWordList.filter((word, index) => index !== indexToRemove)
    );
  }

  async function handleDeleteWords(word) {
    setLoading(true);
    try {
      const responses = await deleteBlockingWordsApi(word);
      toast.success("Word deleted successfully");
      getBlockWords();
      setData(responses);
    } catch (e) {
      toast.error(e?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }
  //================================Handle Submit BlockingWords =================
  async function handleFormSubmit(e) {
    e.preventDefault();

    if (name.trim()) {
      setNameError("Name cant be blank");
      return;
    }

    setLoader(true);
    try {
      const res = await addBlockWordApi(wordList);
      if (res?.status === 200) {
        toast.success("Words added successfully");
        onHide();
        setLoader(false);
      } else if (!res?.status === 200) {
        throw new Error("response.message");
      }
    } catch (error) {
      setLoader(false);
      toast.error(error?.response?.data?.message);
    }
  }

  console.log(data);

  useEffect(() => {
    getBlockWords();
  }, []);

  return (
    <div>
      <Modal show={showModal} onHide={onHide} centered>
        <Card style={{ width: "100%" }}>
          <span className="close" onClick={onHide}>
            &times;
          </span>
          <form className="p-3 mt-2" onSubmit={handleFormSubmit}>
            <h3 className="text-center m-0 mb-3">Add Words</h3>

            <div className="overflow-auto p-1" style={{ maxHeight: "100px" }}>
              <div className="d-flex flex-wrap">
                {wordList.map((word, index) => (
                  <div key={index} className="word-item">
                    <span className="">{word}</span>
                    <button
                      type="button"
                      className="remove-word"
                      onClick={() => handleRemoveWord(index)}
                    >
                      &times;
                    </button>
                  </div>
                ))}
              </div>
            </div>

            <div class="form-field text-area-div" style={{ color: "red" }}>
              <textarea
                type="text"
                autocomplete="off"
                className="text-area"
                placeholder="Enter text "
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <span className="text-danger">{nameError}</span>

            <div className="d-flex align-items-center justify-content-end">
              <Button loader={loader} />
              {name.trim().length > 0 && (
                <button
                  className="bTon ml-2"
                  type="button"
                  onClick={() => handleAddWords()}
                >
                  Add +
                </button>
              )}
            </div>
          </form>

          <div>
            <h3 className="text-center m-0 m-3">Current Words</h3>
            <div className="overflow-auto p-1" style={{ height: "250px" }}>
              {loading ? (
                <div className="d-flex justify-content-center align-items-center">
                  <Spinner animation="border" />
                </div>
              ) : (
                <div className="p-3">
                  {data?.data?.data?.restrictedWords.map((item, index) => (
                    <div
                      key={index}
                      className="d-flex justify-content-between align-items-center border-bottom p-2"
                    >
                      <p className="m-0 font-weight-bold">{item}</p>
                      <button
                        type="button"
                        className="remove-word-btn"
                        onClick={() => handleDeleteWords(item)}
                      >
                        &times;
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </Card>
      </Modal>
    </div>
  );
}

import img1 from "../../images/avatar1.png";
import img2 from "../../images/avatar2.png";
import img3 from "../../images/avatar3.png";
import img4 from "../../images/avatar4.png";
import img5 from "../../images/avatar5.png";
import img6 from "../../images/avatar6.png";
import img7 from "../../images/avatar7.png";
import img8 from "../../images/avatar8.png";
import img9 from "../../images/avatar9.png";
import img10 from "../../images/avatar10.png";
import img11 from "../../images/avatar11.png";
import img12 from "../../images/avatar12.png";
import img13 from "../../images/avatar13.png";
import img14 from "../../images/avatar14.png";
import img15 from "../../images/avatar15.png";
import img16 from "../../images/avatar16.png";

export const avatarImage = [
  {
    name: "avatar1",
    src: img1,
  },
  {
    name: "avatar2",
    src: img2,
  },
  {
    name: "avatar3",
    src: img3,
  },
  {
    name: "avatar4",
    src: img4,
  },
  {
    name: "avatar5",
    src: img5,
  },
  {
    name: "avatar6",
    src: img6,
  },
  {
    name: "avatar7",
    src: img7,
  },
  {
    name: "avatar8",
    src: img8,
  },
  {
    name: "avatar1",
    src: img9,
  },
  {
    name: "avatar2",
    src: img10,
  },
  {
    name: "avatar3",
    src: img11,
  },
  {
    name: "avatar4",
    src: img12,
  },
  {
    name: "avatar5",
    src: img13,
  },
  {
    name: "avatar6",
    src: img14,
  },
  {
    name: "avatar7",
    src: img15,
  },
  {
    name: "avatar8",
    src: img16,
  },
];

import axiosInstance from "../../../services/AxiosInstance";

//================================CONFIG File================================
export const config = {
  bucketName: process.env.REACT_APP_BUCKET_NAME,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESS_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
};

// ===================getAPIS=============================
export async function dashboardApi() {
  const response = await axiosInstance.get(`admin/userManage/dashboard`, {});
  return response;
}

//===================user List api================
export async function userListApi(page, limit, search) {
  const response = await axiosInstance.get(
    `admin/userManage/getUsers?page=${page}&limit=${limit}&search=${search}`,
    {}
  );
  return response;
}

export async function userMessageHistoryApi(id, page, limit) {
  const response = await axiosInstance.get(
    `admin/userManage/getChats?page=${page}&limit=${limit}&userId=${id}`,
    {}
  );
  return response;
}

export async function userApi(search, id) {
  const response = await axiosInstance.get(
    `admin/group/getUser?page=${0}&search=${search}&groupId=${id}`,
    {}
  );
  return response;
}
export async function memberUserListApi(page, limit, id) {
  const response = await axiosInstance.get(
    `admin/group/getUser?page=${page}&Limit=${limit}&groupId=${id}`,
    {}
  );
  return response;
}

export async function viewUserDetails(id) {
  const response = await axiosInstance.get(
    `admin/userManage/userInfo?id=${id}`
  );
  return response;
}

export async function subjectListApi(page, limit) {
  const response = await axiosInstance.get(
    `admin/subject?page=${page}&limit=${limit}`
  );
  return response;
}

export async function topicsListApi(id, page, limit) {
  const response = await axiosInstance.get(
    `admin/subject/topic?subjectId=${id}&page=${page}&limit=${limit}`
  );
  return response;
}

export async function subTopicsListApi(id, topicId, page, limit) {
  console.log(id);
  const response = await axiosInstance.get(
    `admin/subTopic?subjectId=${id}&topicId=${topicId}&page=${page}&limit=${limit}`
  );
  return response;
}
export async function subChildListApi(id) {
  const response = await axiosInstance.get(
    `admin/subTopic/childSubTopic?subTopicId=${id}`
  );
  return response;
}

export async function groupListApi(currentPage, itemsPerPage) {
  const response = await axiosInstance.get(
    `admin/group?page=${currentPage}&limit=${itemsPerPage}`
  );
  return response;
}
export async function getChatsApi(id) {
  const response = await axiosInstance.get(
    `socket/chats?conversationId=${id}&type=public`
  );
  return response;
}
export async function getBlockWordApi() {
  const response = await axiosInstance.get(`admin/userManage/getWords`);
  return response;
}
export async function getReportsApi(type) {
  const response = await axiosInstance.get(
    `admin/userManage/userMessageReport?reportType=${type}`
  );
  return response;
}
///====================post Request======================
//==================Add Subject================
export async function addSubjectApi(formData, image) {
  const data = {
    name: formData?.name,
    image: image,
  };
  const response = await axiosInstance.post(`admin/subject`, data);
  return response;
}
export async function addBlockWordApi(words) {
  const data = {
    words,
  };
  const response = await axiosInstance.post(`admin/userManage/addWords`, data);
  return response;
}

export async function addSubTopicApi(name, topicId, subjectId) {
  console.log(topicId);
  const data = {
    name: name,
    subjectId: subjectId,
    topicId: topicId,
  };
  const response = await axiosInstance.post(`admin/subTopic`, data);
  return response;
}

export async function addSubChildApi(link, urlType, urlName, subTopicId) {
  const data = {
    subTopicId: subTopicId,
    url: link,
    urlType: urlType,
    urlName: urlName,
  };
  const response = await axiosInstance.post(
    `admin/subTopic/childSubTopic`,
    data
  );
  return response;
}
//===================Add Topic==========================
export async function addTopicApi(formData, image, id) {
  const data = {
    subjectId: id,
    topicName: formData?.topicName,
    topicImage: image,
  };
  const response = await axiosInstance.post(`admin/subject/topic`, data);
  return response;
}

//===================Add Group Api========================
export async function addGroupApi(formData, image) {
  const data = {
    image: image,
    groupName: formData?.name,
    description: formData?.description,
  };
  const response = await axiosInstance.post(`admin/group`, data);
  return response;
}

//==================addUsersApi========================
export async function addUserApi(name, email, password) {
  const data = {
    userName: name,
    email,
    password,
  };
  const response = await axiosInstance.post(`admin/userManage/addUser`, data);
  return response;
}

//======================Put Api's=============================
export async function editProfileApi(formData) {
  const data = {};
  const response = await axiosInstance.put(`admin/storeClinic/edit`, data);
  return response;
}

export async function editSubChildApi(
  formData,
  url,
  subTopicId,
  childSubTopicId
) {
  const data = {
    subTopicId: subTopicId,
    childSubTopicId: childSubTopicId,
    url: url,
    urlType: formData?.urlType,
    urlName: formData?.urlName,
  };
  const response = await axiosInstance.put(
    `admin/subTopic/childSubTopic`,
    data
  );
  return response;
}

export async function verifyReportApi(id) {
  const data = { reportId: id };
  const response = await axiosInstance.put(
    `admin/userManage/reportStatus`,
    data
  );
  return response;
}

//=========================Change password Api's==================
export async function changePasswordApi(formData) {
  const data = {
    oldPassword: formData.oldPassword,
    newPassword: formData.newPassword,
  };
  const response = await axiosInstance.put(`admin/auth/changePassword`, data);
  return response;
}
export async function addMemberGroupApi(groupIds, userId) {
  const data = { groupIds: groupIds, userId: userId };
  const response = await axiosInstance.put(`admin/group/addUser`, data);
  return response;
}
export async function blockMemberGroupApi(userId, groupIds) {
  const data = { groupId: groupIds, userId: userId };
  const response = await axiosInstance.put(`admin/group/blockUser`, data);
  return response;
}
export async function blockUserApi(id) {
  const data = { userId: id };
  const response = await axiosInstance.put(`admin/userManage/userBlock`, data);
  return response;
}
export async function acceptMemberApi(id, groupId, key) {
  const data = { userId: id, groupId, type: key };
  const response = await axiosInstance.put(`admin/group/userAccept`, data);
  return response;
}

export async function addNewMemberGroupApi(userId, groupIds) {
  const data = { groupId: groupIds, userId: userId };
  const response = await axiosInstance.put(`admin/group/joinUser`, data);
  return response;
}
//=======================Delete api=====================

//===================Delete Clinic======================
export async function deleteUserApi(id) {
  const response = await axiosInstance.delete(
    `admin/userManage/deleteUser?id=${id}`
  );
  return response;
}

export async function deleteSubjectApi(id) {
  const response = await axiosInstance.delete(`admin/subject?subjectId=${id}`);
  return response;
}

//===============Delete Topic api==========================
export async function deleteTopicApi(id, subjectId) {
  const response = await axiosInstance.delete(
    `admin/subject/topic?subjectId=${subjectId}&topicId=${id}`
  );
  return response;
}

export async function deleteSubTopicApi(id, subjectId, topicId) {
  const response = await axiosInstance.delete(
    `admin/subTopic?subTopicId=${id}&subjectId=${subjectId}&topicId=${topicId}`
  );
  return response;
}

export async function deleteSubChildApi(id, subTopicId) {
  const response = await axiosInstance.delete(
    `admin/subTopic/childSubTopic?childSubTopicId=${id}&subTopicId=${subTopicId}`
  );
  return response;
}
export async function deleteGroupApi(id) {
  const response = await axiosInstance.delete(`admin/group?groupId=${id}`);
  return response;
}

export async function deleteGroupMemberApi(id) {
  const response = await axiosInstance.delete(`admin/group?groupId=${id}`);
  return response;
}
export async function deleteBlockingWordsApi(word) {
  const response = await axiosInstance.delete(
    `admin/userManage/deleteWord?word=${word}`
  );
  return response;
}

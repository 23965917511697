import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import Button from "../Button";
import { Card } from "react-bootstrap";
import { uploadFile } from "react-s3";
import { addTopicApi } from "../Api's/Api";
import AWS from "aws-sdk";

export default function AddTopics({ showModal, onHide, List, subjectId }) {
  const s3 = new AWS.S3();

  const [loader, setLoader] = useState(false);
  const [nameError, setNameError] = useState("");
  const [selectImage, setSelectImage] = useState();
  const [formData, setFormData] = useState({
    topicImage: "",
    topicName: "",
  });

  console.log(subjectId);

  useEffect(() => {
    const handleUpload = async () => {
      if (!formData?.topicImage) {
        console.error("No file selected");
        return;
      }
      setLoader(true);

      const params = {
        Bucket: process.env.REACT_APP_BUCKET_NAME,
        Key: formData?.topicImage?.name,
        Body: formData?.topicImage,
      };

      try {
        const data = await s3.upload(params).promise();
        setSelectImage(data);
      } catch (error) {
        console.error("Upload failed:", error);
      } finally {
        setLoader(false);
      }
    };

    handleUpload();
  }, [formData?.topicImage]);

  async function handleFormSubmit(e) {
    e.preventDefault();
    if (/^\s+$/.test(formData?.topicName)) {
      setNameError("Name cant be blank");
      return;
    }
    setLoader(true);

    try {
      const res = await addTopicApi(formData, selectImage?.key, subjectId);
      if (res?.status === 200) {
        toast.success("Add Topic successful!");
        List();
        onHide();
        setLoader(false);
      } else if (!res?.status === 200) {
        throw new Error("response.message");
      }
    } catch (error) {
      setLoader(false);
      toast.error(error?.response?.data?.message);
    }
  }

  return (
    <div>
      <Modal show={showModal} onHide={onHide} centered>
        <Card style={{ width: "100%" }}>
          <span className="close" onClick={onHide}>
            &times;
          </span>
          <form className="p-3 mt-2" onSubmit={handleFormSubmit}>
            <h3 className="text-center m-0 mb-3">Add Topic</h3>
            <label className="text-black">Image</label>
            <div class="form-field" style={{ color: "red" }}>
              <input
                type="file"
                autocomplete="off"
                accept=".png, .jpg, .jpeg"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    topicImage: e.target.files[0],
                  })
                }
              />
            </div>
            <label className="text-black">Topic Name</label>
            <div class="form-field" style={{ color: "red" }}>
              <input
                type="text"
                autocomplete="off"
                required
                placeholder="Enter Topic"
                value={formData?.topicName}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    topicName: e.target.value,
                  })
                }
              />
            </div>
            <span className="text-danger ml-2 p-0">{nameError}</span>

            <div className="d-flex align-items-center justify-content-end">
              <Button loader={loader} />
            </div>
          </form>
        </Card>
      </Modal>
    </div>
  );
}

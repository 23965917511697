import React, { useState, useEffect } from "react";
import AWS from "aws-sdk";
import defaultImage from "../../images/emptyImg.jpg";

// import { KEYCHAIN } from '../utils/keychain';

const S3Image = ({ imageKey, title }) => {
  const [imageData, setImageData] = useState(null);

  const onImageError = (e) => {
    e.target.src = defaultImage;
  };
  useEffect(() => {
    console.log(imageKey);
    const fetchImageData = async () => {
      try {
        const s3 = new AWS.S3();
        const params = {
          Bucket: process.env.REACT_APP_BUCKET_NAME,
          Key: imageKey,
        };
        const data = await s3.getObject(params).promise();
        const base64Image = data.Body.toString("base64");

        setImageData(base64Image);
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    };

    fetchImageData();
  }, [imageKey]);
  console.log(imageData);

  if (!imageData) {
    return (
      <div>
        {title === "join" ? (
          <img
            height={35}
            width={35}
            style={{ borderRadius: "20px" }}
            src={defaultImage}
          />
        ) : (
          <img height={60} width={60} src={defaultImage} />
        )}
      </div>
    );
  }

  return (
    <div>
      {title === "join" ? (
        <img
          height={35}
          width={35}
          style={{ borderRadius: "20px" }}
          src={`data:image/png;base64,${imageData}`}
          alt="S3 Content"
          onError={onImageError}
        />
      ) : (
        <img
          height={60}
          width={60}
          className="profile-img"
          src={`data:image/png;base64,${imageData}`}
          alt="S3 Content"
          onError={onImageError}
        />
      )}
    </div>
  );
};

export default S3Image;

import React, { Component, useEffect, useState, lazy } from "react";
import moment from "moment";
import Pagination from "../components/Pagination";
import toast, { Toaster } from "react-hot-toast";
import { variable, s3BaseUrl } from "../components/Variable";
import { Dropdown, Card, Table, Badge, Col } from "react-bootstrap";
import Lottie from "lottie-react";
import animationData from "../../images/nursing.json";
import Filters from "../components/Filters";
import UserListingShimmerLoader from "./SkeletonPages/UserListingShimmerLoader";
import { getReportsApi, verifyReportApi } from "../components/Api's/Api";
import emptyImg from "../../images/emptyImg.jpg";

export function Reports(props) {
  const [tableList, setTableList] = useState([]);
  const [loader, setLoader] = useState(true);
  //   const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [tableType, setTableType] = useState("user");

  const filterOption = [
    {
      value: "user",
      label: "User",
    },
    {
      value: "message",
      label: "Message",
    },
  ];
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );

  //=========page calculate=============
  //   const totalItems = subjectList?.data?.data?.countSubjects;
  //   const totalPages = Math.ceil(totalItems / itemsPerPage);

  //=============fetch user List==================
  async function handleTableList() {
    setLoader(true);
    const res = await getReportsApi(tableType);
    setTableList(res);
    setLoader(false);
  }

  //=====================Verify Report================
  async function handleVerifyReport(id) {
    setLoader(true);
    try {
      const res = await verifyReportApi(id);
      toast.success("Verified Report successfully");
      handleTableList();
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoader(false);
    }
  }

  ///===========delete user======================
  //   async function handleDelete(id) {
  //     try {
  //       const res = await deleteSubjectApi(id);
  //       handleSubjectList();
  //     } catch (error) {
  //       console.error("Something bad happened");
  //       console.error(error.response);
  //       throw new Error(error);
  //     }
  //   }

  //   function deletePromise(id) {
  //     toast.promise(handleDelete(id), {
  //       loading: "Saving...",
  //       success: "Delete Successfully!",
  //       error: "try latter.",
  //     });
  //   }

  useEffect(() => {
    handleTableList();
    scrollToTop();
  }, [tableType]);

  return (
    <div>
      <div className="page-header d-flex justify-content-between align-items-end mb-3">
        <h3 className="page-title m-0">Reports List</h3>
        <Filters
          FilterOption={filterOption}
          setFilterType={setTableType}
          filterType={tableType}
          tabs={true}
        />
      </div>
      <div className="row">
        {loader ? (
          <div
            className="d-flex  justify-content-center align-items-center"
            style={{ height: "80vh", width: "100%" }}
          >
            <Lottie animationData={animationData} />
          </div>
        ) : (
          <div className="col-lg-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  {tableType === "user" ? (
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th> Reported By </th>
                          <th> Reported To </th>
                          <th>status</th>
                          <th> Creation Date </th>
                          <th>Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        {tableList?.data?.data?.reportList?.map((item) => (
                          <tr key={item?._id}>
                            <td>{item?.reportedBy?.userName}</td>
                            <td>{item?.reportedTo?.userName}</td>
                            <td>
                              {!item?.status ? (
                                <Badge variant="warning light">Pending</Badge>
                              ) : (
                                <Badge variant="success light">Verify</Badge>
                              )}
                            </td>
                            <td>{moment(item?.createdAt).format("ll")}</td>
                            <td>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="warning light"
                                  className="light sharp btn i-false"
                                >
                                  {svg1}
                                </Dropdown.Toggle>
                                {!item?.status && (
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      onClick={() =>
                                        handleVerifyReport(item?._id)
                                      }
                                    >
                                      Verify
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                )}
                              </Dropdown>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th> Reported By </th>
                          <th> Reported To </th>
                          <th> Message</th>
                          <th>status</th>
                          <th> Creation Date </th>
                          <th>Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        {tableList?.data?.data?.reportList?.map((item) => (
                          <tr key={item?._id}>
                            <td>{item?.reportedBy?.userName}</td>
                            <td>{item?.reportedTo?.userName}</td>
                            <td>{item?.message}</td>
                            <td>
                              {!item?.status ? (
                                <Badge variant="warning light">Pending</Badge>
                              ) : (
                                <Badge variant="success light">Verify</Badge>
                              )}
                            </td>
                            <td>{moment(item?.createdAt).format("ll")}</td>
                            <td>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="warning light"
                                  className="light sharp btn i-false"
                                >
                                  {svg1}
                                </Dropdown.Toggle>
                                {!item?.status && (
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      onClick={() =>
                                        handleVerifyReport(item?._id)
                                      }
                                    >
                                      Verify
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                )}
                              </Dropdown>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                  {tableList?.data?.data?.reportList?.length === 0 && (
                    <div className="justify-content-center d-flex my-5 ">
                      Sorry, Data Not Found!
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Reports;

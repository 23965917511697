import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import Lottie from "lottie-react";
import animationData from "../../../../images/nursing.json";
import { variable, s3BaseUrl } from "../../Variable";
import { Card } from "react-bootstrap";
import { groupListApi, addMemberGroupApi } from "../../Api's/Api";
import S3Image from "../../S3Imge";

export default function JoinGroupList({ showModal, onHide, list }) {
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checkbox, setCheckbox] = useState([]);

  const [userList, setUsersList] = useState("");

  async function handleGroupList() {
    setLoader(true);
    const res = await groupListApi(0, 0);
    setUsersList(res);
    setLoader(false);
  }

  function joinCheckbox(id) {
    const updatedCheckedIds = checkbox.includes(id)
      ? checkbox.filter((checkedId) => checkedId !== id)
      : [...checkbox, id];

    setCheckbox(updatedCheckedIds);
  }

  async function onHandleJoinGroup() {
    setLoading(true);
    try {
      const res = await addMemberGroupApi(checkbox, variable?.id);
      if (res?.status === 200) {
        toast.success("Added successfully");
        list();
        onHide();
        setLoading(false);
      } else if (!res?.status === 200) {
        throw new Error("response.message");
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message);
    }
  }

  useEffect(() => {
    handleGroupList();
  }, []);

  useEffect(() => {
    handleGroupList();
  }, []);

  return (
    <div>
      <Modal show={showModal} onHide={onHide} centered className="group-modal ">
        <Card style={{ height: "500px" }}>
          <span className="close" onClick={onHide}>
            &times;
          </span>

          {loader ? (
            <div
              className="d-flex  justify-content-center align-items-center"
              style={{ height: "80vh" }}
            >
              <Lottie animationData={animationData} />
            </div>
          ) : (
            <div style={{ height: "500px", overflow: "auto" }}>
              {userList?.data?.data?.groups?.map((item) => (
                <div
                  className="d-flex align-items-center p-2 justify-content-start group-content"
                  style={{ gap: "10px" }}
                >
                  <label className="checkbox-container">
                    <input
                      type="checkbox"
                      onChange={() => joinCheckbox(item._id)}
                      checked={checkbox.includes(item._id)}
                    />
                    <span className="checkmark"></span>
                  </label>

                  <S3Image imageKey={item?.image} title={"join"} />
                  <span className="group-name">{item?.groupName}</span>
                </div>
              ))}
            </div>
          )}
          <div className="mt-3 d-flex justify-content-center">
            {checkbox.length > 0 && (
              <button
                className={`join-button ${loading ? "disabled" : ""}`}
                disabled={loading}
                type="submit"
                style={
                  loading
                    ? { pointerEvents: "none", opacity: 0.5 }
                    : { pointerEvents: "auto" }
                }
                onClick={() => onHandleJoinGroup()}
              >
                {loading ? (
                  <div
                    className="d-flex align-items-center justify-content-center "
                    style={{ gap: "5px" }}
                  >
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Loading...
                  </div>
                ) : (
                  "Join"
                )}
              </button>
            )}
          </div>
        </Card>
      </Modal>
    </div>
  );
}

import React, { Component, useEffect, useState } from "react";
import moment from "moment";
import toast, { Toaster } from "react-hot-toast";
import { avatarImage } from "../../GlobalCss/Objects";
import { variable, s3BaseUrl } from "../../components/Variable";
import { Dropdown, Card, Table, Badge, Col } from "react-bootstrap";
import UserListingShimmerLoader from "../SkeletonPages/UserListingShimmerLoader";
import {
  memberUserListApi,
  blockMemberGroupApi,
  acceptMemberApi,
} from "../../components/Api's/Api";
import Pagination from "../../components/Pagination";
import emptyImg from "../../../images/emptyImg.jpg";

export function GroupMember(props) {
  const [usersList, setUsersList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [type, setType] = useState("accepted");
  const [currentPage, setCurrentPage] = useState(0);

  const itemsPerPage = 0;
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const onImageError = (e) => {
    e.target.src = emptyImg;
  };
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );

  //=========page calculate=============
  const totalItems = usersList?.data?.data?.countUser;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  //=============fetch user List==================
  async function handleUserList() {
    setLoader(true);
    const res = await memberUserListApi(
      currentPage,
      itemsPerPage,
      props?.location?.state?.item
    );
    setUsersList(res);
    setLoader(false);
  }

  ///===========delete user======================
  async function handleBlockUser(id) {
    try {
      const res = await blockMemberGroupApi(id, props?.location?.state?.item);
      console.log(res);

      if (res?.data?.data?.updateUser === "User unblocked successfully") {
        handleUserList();
        toast.success("Active Successfully !");
      } else {
        handleUserList();
        toast.error("DeActive successful!");
      }
    } catch (error) {
      console.error(error.response);
    }
  }

  async function handleAcceptMember(id, key) {
    setLoader(true);
    try {
      const res = await acceptMemberApi(id, props?.location?.state?.item, key);
      console.log(res);
      if (res?.data?.data?.userAccepted == "User Request Accepted") {
        handleUserList();
        toast.success("Request Accepted Successfully !");
        setLoader(false);
      } else {
        toast.error("Request Rejected !");
        setLoader(false);
        handleUserList();
      }
    } catch (error) {
      console.error(error.response?.data?.message);
      setLoader(false);
    }
  }

  const findAvatarItem = (user) => {
    return avatarImage.find(
      (item) => user?.user?.profile?.profileImage === item.name
    );
  };
  useEffect(() => {
    handleUserList();
    scrollToTop();
  }, [currentPage]);

  useEffect(() => {
    handleUserList();
  }, [type]);

  return (
    <div>
      <div className="page-header d-flex justify-content-between align-items-center mb-3">
        <h3 className="page-title"> Group Members </h3>
      </div>
      <div className="d-flex justify-content-end mb-3">
        <select
          style={{
            width: "10%",
            color: "#7e7e7e",
            padding: " 7px",
            borderColor: "lightgrey",
            borderRadius: "6px",
          }}
          onChange={(event) => setType(event.target.value)}
        >
          <option value="accepted">Accepted </option>
          <option value="pending">Pending </option>
        </select>
      </div>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th> image </th>
                      <th> User name </th>
                      <th> Email </th>
                      <th> Status </th>
                      <th> Action </th>
                    </tr>
                  </thead>
                  {loader ? (
                    <UserListingShimmerLoader count={10} />
                  ) : (
                    <>
                      {type === "accepted" ? (
                        <tbody>
                          {usersList?.data?.data?.users?.map((item) => (
                            <tr key={item?._id}>
                              <td className="py-1">
                                {item?.user?.profile?.profileType ===
                                "avtar" ? (
                                  <img
                                    className="profile-img"
                                    src={
                                      (findAvatarItem(item) &&
                                        findAvatarItem(item).src) ||
                                      emptyImg
                                    }
                                    onError={onImageError}
                                    alt="user icon"
                                  />
                                ) : (
                                  <img
                                    className="profile-img"
                                    src={
                                      item?.user?.profile?.profileImage ||
                                      emptyImg
                                    }
                                    onError={onImageError}
                                    alt="user icon"
                                  />
                                )}
                              </td>
                              <td>
                                {item?.user?.userName}{" "}
                                {/* <span>{item?.lastName}</span> */}
                              </td>
                              {/* <td>
                            {item?.isRequest ? (
                              <Badge variant="danger light">padding</Badge>
                            ) : (
                              <Badge variant="success light">Accepted</Badge>
                            )}
                          </td> */}
                              <td>{item?.user?.email}</td>
                              <td>
                                {item?.isBlocked ? (
                                  <Badge variant="danger light">
                                    isBlocked
                                  </Badge>
                                ) : (
                                  <Badge variant="success light">Active</Badge>
                                )}
                              </td>
                              {/* <td>{moment(item?.createdAt).format("ll")}</td> */}
                              <td>
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant="warning light"
                                    className="light sharp btn i-false"
                                  >
                                    {svg1}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    {item.isBlocked ? (
                                      <Dropdown.Item
                                        onClick={() =>
                                          handleBlockUser(item?.user?._id)
                                        }
                                      >
                                        Active
                                      </Dropdown.Item>
                                    ) : (
                                      <Dropdown.Item
                                        onClick={() =>
                                          handleBlockUser(item?.user?._id)
                                        }
                                      >
                                        Block
                                      </Dropdown.Item>
                                    )}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                            </tr>
                          ))}
                          {!usersList?.data?.data?.users && (
                            <div
                              className="justify-content-center d-flex"
                              style={{
                                position: "absolute",
                                position: "sticky",
                                lineHeight: "8rem",
                                left: "300px",
                              }}
                            >
                              Sorry, Data Not Found!
                            </div>
                          )}
                        </tbody>
                      ) : (
                        <tbody>
                          {usersList?.data?.data?.requestedGroup?.requested?.map(
                            (item) => (
                              <tr key={item?._id}>
                                <td className="py-1">
                                  {item?.profile?.profileType === "avtar" ? (
                                    <img
                                      className="profile-img"
                                      src={
                                        (findAvatarItem() &&
                                          findAvatarItem().src) ||
                                        emptyImg
                                      }
                                      onError={onImageError}
                                      alt="user icon"
                                    />
                                  ) : (
                                    <img
                                      className="profile-img"
                                      src={
                                        item?.profile?.profileImage || emptyImg
                                      }
                                      onError={onImageError}
                                      alt="user icon"
                                    />
                                  )}
                                </td>
                                <td>{item?.userName} </td>

                                <td>{item?.email}</td>
                                <td>
                                  {item?.isBlocked ? (
                                    <Badge variant="danger light">
                                      isBlocked
                                    </Badge>
                                  ) : (
                                    <Badge variant="success light">
                                      Active
                                    </Badge>
                                  )}
                                </td>
                                <td>
                                  <Dropdown>
                                    <Dropdown.Toggle
                                      variant="warning light"
                                      className="light sharp btn i-false"
                                    >
                                      {svg1}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <Dropdown.Item
                                        onClick={() =>
                                          handleAcceptMember(
                                            item?._id,
                                            "reject"
                                          )
                                        }
                                      >
                                        Reject Request
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        onClick={() =>
                                          handleAcceptMember(
                                            item?._id,
                                            "accept"
                                          )
                                        }
                                      >
                                        Accept Request
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </td>
                              </tr>
                            )
                          )}
                          {usersList?.data?.data?.requestedGroup?.requested
                            ?.length === 0 && (
                            <div
                              className="justify-content-center d-flex  "
                              style={{
                                position: "absolute",
                                position: "sticky",
                                lineHeight: "8rem",
                                left: "300px",
                              }}
                            >
                              Sorry, Data Not Found!
                            </div>
                          )}
                        </tbody>
                      )}
                    </>
                  )}
                </table>
                {/* 
                {usersList?.length !== 0 && (
                  <div className="card-footer clearfix">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Page {currentPage * itemsPerPage + 1} to{" "}
                        {totalItems > (currentPage + 1) * itemsPerPage
                          ? (currentPage + 1) * itemsPerPage
                          : totalItems}{" "}
                        of {totalItems} entries
                      </div>

                      <Pagination
                        pageCount={totalPages}
                        pageValue={currentPage}
                        setPage={setCurrentPage}
                      />
                    </div>
                  </div>
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GroupMember;

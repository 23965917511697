import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import Button from "../Button";
import { Card } from "react-bootstrap";
import { editSubChildApi } from "../Api's/Api";
import AWS from "aws-sdk";

export default function EditSubChild({ showModal, onHide, list, id, data }) {
  const [loader, setLoader] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [youUrl, setYouUrl] = useState("");
  const [urlName, setUrlName] = useState(data?.urlName);
  const [selectImage, setSelectImage] = useState("");
  // let link = "";
  // let youtubeUrl = "";
  // let pdfUrl = "";
  const s3 = new AWS.S3();

  const [urlType, setUrlType] = useState(data?.urlType);

  // function handleUrlSetUrl(formData) {
  //   if (formData?.urlType === "pdf url") {
  //     pdfUrl = formData?.url;
  //   } else if (formData?.urlType === "youtube url") {
  //     youtubeUrl = formData?.url;
  //   }
  // }

  const handleUpload = async () => {
    if (!pdfUrl) {
      console.error("No file selected");
      return;
    }
    setLoader(true);

    const params = {
      Bucket: process.env.REACT_APP_BUCKET_NAME,
      Key: pdfUrl?.name,
      Body: pdfUrl,
    };

    try {
      const data = await s3.upload(params).promise();
      setSelectImage(data);
    } catch (error) {
      console.error("Upload failed:", error);
    } finally {
      setLoader(false);
    }
  };

  console.log(selectImage);
  useEffect(() => {
    if (urlType === "pdf url") {
      handleUpload();
    }
  }, [pdfUrl]);

  useEffect(() => {
    if (urlType === "pdf url") {
      setPdfUrl(data?.url);
    } else {
      setYouUrl(data?.url);
    }
  }, [data]);

  async function handleFormSubmit(e) {
    // e.preventDefault();
    // setLoader(true);
    // if (selectImage?.Location?.length > 0) {
    //   link = selectImage?.Location;
    // } else {
    //   link = formData?.url;
    // }
    // try {
    //   const res = await editSubChildApi(formData, link, id, data?._id);
    //   if (res?.status === 200) {
    //     toast.success("Updated successfully!");
    //     list();
    //     onHide();
    //     setLoader(false);
    //   } else if (!res?.status === 200) {
    //     throw new Error("response.message");
    //   }
    // } catch (error) {
    //   setLoader(false);
    //   toast.error(error?.response?.data?.data);
    // }
  }

  return (
    <div>
      <Modal show={showModal} onHide={onHide} centered>
        <Card style={{ width: "100%" }}>
          <span className="close" onClick={onHide}>
            &times;
          </span>
          <form className="p-3 mt-2" onSubmit={handleFormSubmit}>
            <h3 className="text-center m-0 mb-3">Edit SubChild</h3>
            {urlType === "pdf url" ? (
              <>
                <label className="text-black">Select File</label>
                <div className="form-field">
                  <input
                    type="file"
                    accept=".pdf"
                    onChange={(e) => setPdfUrl(e.target.files[0])}
                  />
                </div>
              </>
            ) : (
              <>
                <label className="text-black">Enter Url</label>
                <div className="form-field" style={{ color: "red" }}>
                  <input
                    type="url"
                    required
                    value={youUrl}
                    autocomplete="off"
                    placeholder="url"
                    onChange={(e) => setYouUrl(e.target.value)}
                  />
                </div>
              </>
            )}
            <label className="text-black">Url Name</label>
            <div class="form-field" style={{ color: "red" }}>
              <input
                type="text"
                autocomplete="off"
                required
                placeholder="Enter url Name"
                value={urlName}
                onChange={(e) => setUrlName(e.target.value)}
              />
            </div>

            <label className="text-black font-w500">SubTopic Type</label>
            <div className="form-field">
              <select
                style={{ border: "none", borderRadius: "30px" }}
                required="required"
                className="form-control"
                value={urlType}
                onChange={(e) => setUrlType(e.target.value)}
              >
                <option hidden>Select..</option>
                <option value="youtube url">Youtube url</option>
                <option value="pdf url">Pdf url</option>
              </select>
            </div>

            <div className="d-flex align-items-center justify-content-end">
              <Button loader={loader} />
            </div>
          </form>
        </Card>
      </Modal>
    </div>
  );
}

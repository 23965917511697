import { useState } from "react";
import { Modal } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { Card } from "react-bootstrap";
import Button from "../Button";
import { addSubjectApi } from "../Api's/Api";

export default function Subjects({ showModal, onHide, List }) {
  const [loader, setLoader] = useState(false);
  const [nameError, setNameError] = useState("");

  const [formData, setFormData] = useState({
    name: "",
  });
  async function handleFormSubmit(e) {
    e.preventDefault();

    if (/^\s+$/.test(formData.name)) {
      setNameError("Name cant be blank");
      return;
    }

    setLoader(true);

    try {
      const res = await addSubjectApi(formData);
      if (res?.status === 200) {
        toast.success("Subject added successfully");
        List();
        onHide();
        setLoader(false);
      } else if (!res?.status === 200) {
        throw new Error("response.message");
      }
    } catch (error) {
      setLoader(false);
      toast.error(error?.response?.data?.message);
    }
  }

  return (
    <div>
      <Modal show={showModal} onHide={onHide} centered>
        <Card style={{ width: "100%" }}>
          <span className="close" onClick={onHide}>
            &times;
          </span>
          <form className="p-3 mt-2" onSubmit={handleFormSubmit}>
            <h3 className="text-center m-0 mb-3">Add Subject</h3>
            {/* <label className="text-black">Image</label>
            <div class="form-field" style={{ color: "red" }}>
              <input
                type="file"
                required
                autocomplete="off"
                accept=".png, .jpg, .jpeg"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    image: e.target.files[0],
                  })
                }
              />
            </div> */}
            <label className="text-black">Subject Name</label>
            <div class="form-field" style={{ color: "red" }}>
              <input
                type="text"
                autocomplete="off"
                required
                placeholder="Enter Subject"
                value={formData?.name}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    name: e.target.value,
                  })
                }
              />
            </div>
            <span className="text-danger">{nameError}</span>

            <div className="d-flex align-items-center justify-content-end">
              <Button loader={loader} />
            </div>
          </form>
        </Card>
      </Modal>
    </div>
  );
}
